import React from "react";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  ContactForm
} from "@fishrmn/fishrmn-components";
import emailCallback from "../utils/email.js";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Thai Amarin | Catering</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Catering"}
              tagline={""}
              images={[
                "https://lh3.googleusercontent.com/p/AF1QipP1qOOOkYb6fKqVmF-k5h3q-_M0gpk-t3G_Wk9x=s1600-w1600-h1600"
              ]}
              showMap={false}
              ctas={[
                { text: "Menu", link: "/menu/", internal: true },
                {
                  text: "(617) 527-5255",
                  link: "tel:(617) 527-5255",
                  internal: false
                }
              ]}
              ctaColor={"white"}
              ctaInverted={false}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={true}
              containerAs={"solid"}
              height={"100vh"}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <ContactForm
                fields={[
                  {
                    label: "name",
                    placeholder: "Name",
                    type: "input",
                    required: true
                  },
                  {
                    label: "phone",
                    placeholder: "Phone",
                    type: "input",
                    required: true
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    required: true
                  },
                  {
                    label: "cateringItems",
                    placeholder: "Catering Items",
                    type: "textarea",
                    required: true
                  }
                ]}
                buttonText={"Send"}
                centeredHeaders={false}
                className={""}
                fluidButton={true}
                header={"Submit A Catering Request"}
                inline={false}
                onSubmit={emailCallback}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}
